import React, { useState, useEffect, useContext } from "react";
import nemateAktivnosti from "../../assets/panel/nemateAktivnosti.svg";
import ActivityTable from "./partials/activityTable/ActivityTable";
import axios from "axios"
import moment from "moment"
import {store} from '../../Store';
import Header from './partials/header/Header'
import backButton from "../../assets/panel/backButton.svg";
import DataCircle from "./partials/dataCircle/dataCircle"
import DataPlate from "./partials/dataPlate/dataPlate"

export default function Analytics({ ...props }) {
  const [dataView, setDataView] = React.useState('prihodnji');
  const {state, dispatch} = useContext(store);

  console.log(state)

  const [nextWeekSessions, setNextWeekSessions] = useState([]);
  const [todaysSessions, setTodaysSessions] = useState([]);
  const [dayBeforeSessions, setDayBeforeSessions] = useState([]);
  const [monthAgoSessions, setMonthAgoSessions] = useState([]);
  const [todaysSessionsThatPassed, setTodaysSessionsThatPassed] = useState([]);

  const currentDateEndThAgo = moment().subtract(30, "minutes").toISOString();

  const currentDateEnd = moment().set({hour:25,minute:0,second:0,millisecond:0}).toISOString();
  const currentDateStart = moment().set({hour:3,minute:0,second:0,millisecond:0}).toISOString();

  const dayBeforeCurrentDateStart = moment().subtract(1, 'day').set({hour:3,minute:0,second:0,millisecond:0}).toISOString();
  const twoDaysAhead = moment().add(3, 'day').set({hour:1,minute:0,second:0,millisecond:0}).toISOString();
  const startOfMonth = moment().clone().startOf('year').subtract(11, 'year').set({hour:3,minute:0,second:0,millisecond:0}).toISOString();
  const currentTime = new Date().toISOString();

  const recivedState = props.location.state;

  console.table("CurrentDateStart", currentDateStart, currentDateEndThAgo)

  useEffect(async () => {
    setNextWeekSessions(await getSessions(state.data.user._id, currentDateEnd, twoDaysAhead, 100));
    setTodaysSessions(await getSessions(state.data.user._id, currentDateEndThAgo, currentDateEnd, 100));
    setTodaysSessionsThatPassed(await getSessions(state.data.user._id, currentDateStart, currentDateEndThAgo, 100));
    setDayBeforeSessions(await getSessions(state.data.user._id, dayBeforeCurrentDateStart, currentDateStart, 100));
    setMonthAgoSessions(await getSessions(state.data.user._id, startOfMonth, dayBeforeCurrentDateStart, 100));
  }, [])


   const getSessions = async (doctorId, startDate, endDate, limit) => {
    return axios.get(`${process.env.REACT_APP_API_URL}sessions/analyticsessions/${startDate}/${endDate}/${limit}/${process.env.REACT_APP_PROJECT}`, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table(startDate, endDate, response.data)
       return response.data;
    });
  }

  const renderActivity = () => {
    return (
      <>
        <div
          className="section-header"
          style={{
            marginBottom: "2em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "normal",
            alignItems: "normal",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{fontSize:"34px", color: "#575757", fontWeight: "bold"}}>Ocene posvetov</h4>
            </div>
            {/* <div className="button-grey" onClick={() => props.history.replace('nastavitve')}>Nastavitve prostih terminov</div> */}
          </div>
   
        </div>
        <hr />
        <div
          className="section-content"
          style={{ height: "auto", color: "#707070", paddingTop: "1em" }}
        >

        <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{fontSize:"34px", color: "#575757", fontWeight: "bold"}}>Povprečen odstotek ocen</h4>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(14%, 3fr))", marginTop: "2em"}}>
              {console.log(recivedState)}
              {console.log(recivedState.data.general.averagesByRating['slab'])}
              <DataCircle borderColor="#5CC8BD" borderWidth="5px" circleSize="100px" title="odlicno" isPercentage percentage={recivedState.data.general.averagesByRating['odlicno']}/>
              <DataCircle borderColor="#ACE3DD" borderWidth="5px" circleSize="100px" title="dobro" isPercentage percentage={recivedState.data.general.averagesByRating['dobro']}/>
              <DataCircle borderColor="#FCB13E" borderWidth="5px" circleSize="100px" title="povprecno" isPercentage percentage={recivedState.data.general.averagesByRating['povprecno']}/>
              <DataCircle borderColor="#F4D7D4" borderWidth="5px" circleSize="100px" title="slab" isPercentage percentage={recivedState.data.general.averagesByRating['slab']}/>
              <DataCircle borderColor="#C4385B" borderWidth="5px" circleSize="100px" title="grozno" isPercentage percentage={recivedState.data.general.averagesByRating['grozno']}/>

            </div>

            <hr  style={{marginTop: "2em"}}/>

            <div style={{ display: "flex", flexDirection: "column", marginTop: "1em"}}>
              <h4 style={{fontSize:"34px", color: "#575757", fontWeight: "bold"}}>Povprečen odstotek katere stvari so pri posvetu všeč</h4>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(14%, 3fr))", marginTop: "2em"}}>
              {console.log(recivedState)}
              {console.log(recivedState.data.general.addiotinalRatings['vsebina'])}
              <DataCircle borderColor="#5CC8BD" borderWidth="5px" circleSize="100px" title="Vsebina posveta" isPercentage percentage={recivedState.data.general.addiotinalRatings['vsebina']}/>
              <DataCircle borderColor="#ACE3DD" borderWidth="5px" circleSize="100px" title="Prijaznost" isPercentage percentage={recivedState.data.general.addiotinalRatings['prijaznost']}/>
              <DataCircle borderColor="#FCB13E" borderWidth="5px" circleSize="100px" title="odnos" isPercentage percentage={recivedState.data.general.addiotinalRatings['odnos']}/>
              <DataCircle borderColor="#F9D092" borderWidth="5px" circleSize="100px" title="strokovnost" isPercentage percentage={recivedState.data.general.addiotinalRatings['strokovnost']}/>
              <DataCircle borderColor="#F4D7D4" borderWidth="5px" circleSize="100px" title="enostavnost" isPercentage percentage={recivedState.data.general.addiotinalRatings['enostavnost']}/>
              <DataCircle borderColor="#C4385B" borderWidth="5px" circleSize="100px" title="hitrost" isPercentage percentage={recivedState.data.general.addiotinalRatings['hitrost']}/>
            </div>


            <hr  style={{marginTop: "2em"}}/>

          <div style={{ display: "flex", flexDirection: "column", marginTop: "1em"}}>
            <h4 style={{fontSize:"34px", color: "#575757", fontWeight: "bold"}}>Doplačilo storitve</h4>
          </div>
          <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(14%, 3fr))", marginTop: "2em"}}>
            {console.log(recivedState)}
            {console.log(recivedState.data.general.addiotinalRatings['vsebina'])}
            <DataCircle borderColor="#5CC8BD" borderWidth="5px" circleSize="100px" title="Da" isPercentage percentage={recivedState.data.general.additionalPayment['yes']}/>
            <DataCircle borderColor="#C4385B" borderWidth="5px" circleSize="100px" title="Ne" isPercentage percentage={recivedState.data.general.additionalPayment['no']}/>
          </div>


          <div style={{ display: "flex", flexDirection: "column", marginTop: "2em", marginBottom: "2em"}}>
              <h4 style={{fontSize:"18px", color: "#000", fontWeight: "bold"}}>Koliko bi bili za takšno storitev pripravljeni plačati?</h4>
            </div>


            <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(30%, 3fr))", marginTop: "1em", background: "#F8F8F8", borderRadius: "10px", paddingTop: "1em", paddingBottom: "1em"}}>
              {console.log(recivedState)}
              {console.log(recivedState.data.general.addiotinalRatings['vsebina'])}
              <DataPlate plateHeight="70px" plateWidth="90%" background="#FFFFFF" borderRadius="8px" title="do 2.5 € na mesec" isPercentage percentage={recivedState.data.general.averagesByPricing['firstPrice']}/>
              <DataPlate plateHeight="70px" plateWidth="90%" background="#FFFFFF" borderRadius="8px" title="do 5 € na mesec" isPercentage percentage={recivedState.data.general.averagesByPricing['secondPrice']}/>
              <DataPlate plateHeight="70px" plateWidth="90%" background="#FFFFFF" borderRadius="8px" title="do 10 € na mesec" isPercentage percentage={recivedState.data.general.averagesByPricing['thirdPrice']}/>
            
            </div>


            <div style={{ height: "4em"}}></div>

        </div>
      </>
    );
  };

  return (
<>
<Header/>
    <div className="analytics-section">
      <div className="sivo" > </div>


      <div className="section-container">
      <div
            className="section-header"
            style={{ flexDirection: "column", alignItems: "normal" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "2em 0",
                cursor: "pointer"
              }}
              onClick={() => props.history.replace('pregled')}
            >
              <img src={backButton}></img>
              <h3
                style={{ weight: "400", marginLeft: "20px", fontSize: "24px" }}
              >
                Pojdi nazaj
              </h3>
            </div>
          </div>
        {renderActivity()}</div>
    </div>
</>
  );
}
